<template>
  <div>
    <div class="search">
      <div class="searchFl">
        <portSelect
          v-model="searchQuery.delivery_place"
          placeTitle="请输入目的港"
          ref="portSelectRef"
          clearable
          @input="dest_port_input"
        />

        <el-input style="width:230px;margin-left:20px" clearable size="small" v-model="searchQuery.job_no" placeholder="请输入订单编号"></el-input>

        <!-- 委托单位 -->
        <el-input style="width:230px;margin-left:20px" clearable v-model="searchQuery.customer" size="small" placeholder="请输入委托单位"></el-input>
        <template v-if="USER_INFO.user_type == 1">
          <el-select v-model="searchQuery.spell_type" clearable placeholder="整拼类型" style="width:230px;margin-left:20px" size="small" >
            <el-option label="整箱" :value="1"></el-option>
            <el-option label="拼箱" :value="2"></el-option>
            <el-option label="其他" :value="3"></el-option>
          </el-select>
        </template>
        <template v-if="USER_INFO.user_type == 2">
          <el-select v-model="searchQuery.spell_type" clearable placeholder="整拼类型" style="width:230px;margin-left:20px" size="small" >
            <el-option label="整箱" :value="1"></el-option>
            <el-option label="自拼箱" :value="6"></el-option>
            <el-option label="委托拼箱" :value="7"></el-option>
            <el-option label="其他" :value="3"></el-option>
          </el-select>
        </template>
        <el-date-picker
        :clearable='true'
        style="margin-left:20px"
          v-model="searchQuery.etd_time"
          type="daterange"
          size="small"
          range-separator="-"
          start-placeholder="开航开始日期"
          end-placeholder="开航结束日期"
        >
        </el-date-picker>
        <el-date-picker
        :clearable='true'
        style="margin-left:20px"
          v-model="searchQuery.created_time"
          type="daterange"
          size="small"
          range-separator="-"
          start-placeholder="创建开始日期"
          end-placeholder="创建结束日期"
        >
        </el-date-picker>
        <el-select v-model="searchQuery.status" clearable placeholder="订单状态" style="width:230px;margin-left:20px" size="small" >
          <el-option v-for="(item,i) in statusArr" :key="i" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </div>
      <div>
        <el-button size="small" type="primary" @click="searchChange">查询</el-button>
        <el-button size="small" @click="resetChange">{{
          $t("reset")
        }}</el-button>
      </div>
    </div>
    <div class="search" v-if="this.USER_INFO.user_type == 2">
      <div class="searchFl">
        <shipCompany
          :placeTitle="'承运人(即船公司)'"
          :width="'150px'"
          ref="shipCompanySelectRef"
          v-model="searchQuery.shipping_company"
        ></shipCompany>
        <el-input style="width:174px;margin-left:20px" clearable v-model="searchQuery.mbl_no" size="small" placeholder="主单号(即MB/L No)"></el-input>
      </div>
    </div>
    <div class="line"></div>
    <div class="tableBlock" style="padding-top:10px">
      <el-table
          :data="tableData"
          style="width: 100%;"
          :height="tableHeight"
          sortable="custom"
          @sort-change="sortChange"
          stripe
          :border="true"
          ref="tbaleRef"
          v-tableHeight="{ bottomOffset: 74 }"
          v-loading="loading"
          current-row-key="id"
      >
        <template v-if="this.USER_INFO.user_type == 2">
          <el-table-column label="编号" :show-overflow-tooltip="true" width="160">
            <div slot-scope="scope">{{ scope.row.job_no | textFormat }}</div>
          </el-table-column>
          <el-table-column label="主单号"  :show-overflow-tooltip="true" width="120">
            <div slot-scope="scope">{{ scope.row.mbl_no | textFormat }}</div>
          </el-table-column>
          <el-table-column label="承运人"  :show-overflow-tooltip="true" width="160">
            <div slot-scope="scope">{{ scope.row.shipping_company_name_zh ||  scope.row.shipping_company_name_en | textFormat }}</div>
          </el-table-column>
          <el-table-column label="船名航次"  :show-overflow-tooltip="true" width="120">
            <div slot-scope="scope">{{ scope.row.vessel | textFormat }} / {{ scope.row.voyage_flight | textFormat }}</div>
          </el-table-column>
          <el-table-column label="委托单位"  :show-overflow-tooltip="true" width="160">
            <div slot-scope="scope">{{ scope.row.entrust_company_zh | textFormat }}</div>
          </el-table-column>
          <el-table-column label="合约状态"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.customer_level | customerLevel }}</div>
          </el-table-column>
          <el-table-column label="起运港"  :show-overflow-tooltip="true" width="120">
            <div slot-scope="scope">{{ scope.row.from_port | textFormat }}</div>
          </el-table-column>
          <el-table-column label="目的港"  :show-overflow-tooltip="true" width="120">
            <div slot-scope="scope">{{ scope.row.dest_port | textFormat }}</div>
          </el-table-column>
          <el-table-column label="整拼类型"  :show-overflow-tooltip="true" width="120">
            <template slot-scope="scope">
              <template v-if="scope.row.spell_type == 1">整箱</template>
              <template v-else-if="scope.row.spell_type == 3">其他</template>
              <template v-else-if="scope.row.spell_type == 2 && USER_INFO.user_type == 2">
                <template v-if="scope.row.lcl_classes == 1">自拼箱</template>
                <template v-else-if="scope.row.lcl_classes == 2">委托拼箱</template>
              </template>
              <template v-else-if="scope.row.spell_type == 2">拼箱</template>
            </template>
          </el-table-column>
          <el-table-column label="订舱代理"  :show-overflow-tooltip="true" width="160">
            <div slot-scope="scope">{{ scope.row.booking_agent_name_zh | textFormat }}</div>
          </el-table-column>
          <el-table-column label="委托件数"  :show-overflow-tooltip="true" width="120">
            <div slot-scope="scope">{{ scope.row.entrust_quantity | textFormat }}</div>
          </el-table-column>
          <el-table-column label="委托毛重"  :show-overflow-tooltip="true" width="120">
            <div slot-scope="scope">{{ scope.row.entrust_gross_weight | kgsFormat }}</div>
          </el-table-column>
          <el-table-column label="委托体积"  :show-overflow-tooltip="true" width="120">
            <div slot-scope="scope">{{ scope.row.entrust_volume | textFormat }}</div>
          </el-table-column>
          <el-table-column label="开航日期"  width="130" sortable='custom' prop="2" :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.etd | secondFormat('LL') }}</div>
          </el-table-column>
          <el-table-column label="实际到港日期" width="130" :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.actual_arrival_date | secondFormat('LL') }}</div>
          </el-table-column>
          <!-- <el-table-column label="截关日期"  width="130"  sortable='custom' prop="4" :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.cod | secondFormat('LL') }}</div>
          </el-table-column> -->
          <el-table-column label="利润"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.profit | textFormat }}</div>
          </el-table-column>
          <el-table-column label="收入"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.receivable | textFormat }}</div>
          </el-table-column>
          <el-table-column label="支出"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.payable | textFormat }}</div>
          </el-table-column>
          <el-table-column label="操作"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.operator_name | textFormat }}</div>
          </el-table-column>
          <el-table-column label="海外客服"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.overseas_customer_service_name | textFormat }}</div>
          </el-table-column>
          <el-table-column label="订舱"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.booking_status | textFormat }}</div>
          </el-table-column>
          <el-table-column label="创建日期"   width="130" :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.created_time | secondFormat('LL') }}</div>
          </el-table-column>
          <el-table-column label="创建人"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.creator_name_zh | textFormat }}</div>
          </el-table-column>
          <el-table-column label="状态"  sortable="custom" prop="5" :show-overflow-tooltip="true">
              <div slot-scope="scope" :class="{
                  black:scope.row.status==1,
                  blue:scope.row.status==2,
                  aaa:scope.row.status==3,
                  success:scope.row.status==4,
                  gray:scope.row.status==5,
                  red:scope.row.status==6}" >
                {{ scope.row.status | status_format }}
              </div>
          </el-table-column>
          <el-table-column width="105" fixed="right">
              <el-button type="warning" size="mini" slot-scope="scope" @click="handleDetail(scope.row)">委托详情</el-button>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column label="订单编号" :show-overflow-tooltip="true" width="160">
            <div slot-scope="scope">{{ scope.row.job_no | textFormat }}</div>
          </el-table-column>
          <el-table-column label="委托单位"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.entrust_company_zh | textFormat }}</div>
          </el-table-column>
          <el-table-column label="起运港"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.from_port | textFormat }}</div>
          </el-table-column>
          <el-table-column label="目的港"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.dest_port | textFormat }}</div>
          </el-table-column>
          <el-table-column label="整拼类型"  :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <template v-if="scope.row.spell_type == 1">整箱</template>
              <template v-else-if="scope.row.spell_type == 3">其他</template>
              <template v-else-if="scope.row.spell_type == 2 && USER_INFO.user_type == 2">
                <template v-if="scope.row.lcl_classes == 1">自拼箱</template>
                <template v-else-if="scope.row.lcl_classes == 2">委托拼箱</template>
              </template>
              <template v-else-if="scope.row.spell_type == 2">拼箱</template>
            </template>
          </el-table-column>
          <el-table-column label="船名航次"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.vessel | textFormat }} / {{ scope.row.voyage_flight | textFormat }}</div>
          </el-table-column>
          <el-table-column label="委托毛重"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.entrust_gross_weight | kgsFormat }}</div>
          </el-table-column>
          <el-table-column label="开航日期"  width="130" sortable='custom' prop="2" :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.etd | secondFormat('LL') }}</div>
          </el-table-column>
          <el-table-column label="截单日期" width="130"  sortable='custom' prop="3" :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.dod | secondFormat('LL') }}</div>
          </el-table-column>
          <el-table-column label="截关日期"  width="130"  sortable='custom' prop="4" :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.cod | secondFormat('LL') }}</div>
          </el-table-column>
          <el-table-column label="订舱"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.booking_status | textFormat }}</div>
          </el-table-column>
          <el-table-column label="创建日期"   width="130" :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.created_time | secondFormat('LL') }}</div>
          </el-table-column>
          <el-table-column label="创建人"  :show-overflow-tooltip="true">
            <div slot-scope="scope">{{ scope.row.creator_name_zh | textFormat }}</div>
          </el-table-column>
          <el-table-column label="状态"  sortable="custom" prop="5" :show-overflow-tooltip="true">
              <div slot-scope="scope" :class="{
                  black:scope.row.status==1,
                  blue:scope.row.status==2,
                  aaa:scope.row.status==3,
                  success:scope.row.status==4,
                  gray:scope.row.status==5,
                  red:scope.row.status==6}" >
                {{ scope.row.status | status_format }}
              </div>
          </el-table-column>
          <el-table-column width="105">
              <el-button type="warning" size="mini" slot-scope="scope" @click="handleDetail(scope.row)">委托详情</el-button>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
          @current-change="handleChange"
          :page-size="15"
          layout="total, prev, pager, next"
          :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import portSelect from "~ymp/components/common/portSelect";
import shipCompany from "~ymp/components/common/shipCompany"
import { CKstore } from '~/baseUtils/storage'
const statusArr=[
    // 状态：0-待接单；1-正操作；2-预提交；3-已提交；4-已审核；5-已退关
    {value:0,label:"待审核"},
    {value:1,label:"正操作"},
    {value:2,label:"预提交"},
    {value:3,label:"已提交"},
    {value:4,label:"已审核"},
    {value:5,label:"已退关"},
    {value:6,label:"已拒接"},
]
export default {
  data() {
    return {
        statusArr,
        loading: false,
        total: 0,
        currentPage: 1,
        tableHeight: 100,
        tableData: [],
        searchQuery: {
            delivery_place:"",//目的港
            job_no:"",// 委托编号
            customer:"",//  合作伙伴公司名== 委托单位
            spell_type:"",// 整拼类型
            etd_time: [],// 开航时间
            created_time: [],// 创建时间
            status:"",
            sort_type:"",
            sort_key:"",
            shipping_company:"",
            mbl_no:""
        },
        dialog: false,
        dialogType: 'add',
        detail: {}
    };
  },
  name: 'shippingOrderList',
  components:{
      portSelect, shipCompany
  },
  mounted() {
    if(this.USER_INFO.user_type == 2){ // 内部账号
      this.$log({
        type: 2,
        topic: "ymp.workbench",
        id: "ymp.workbench.shippingOrder",
      });
    }else{
      this.$log({
        type: 2,
        topic: "ymp.workbench",
        id: "ymp.workbench.myOrder",
      });
    }
    if(CKstore.get('shippingOrderList')){
      this.searchQuery = Object.assign(this.searchQuery, JSON.parse(CKstore.get('shippingOrderList')))
    }
    this.getList();
  },
  filters:{
    customerLevel(type) {
      let str = "-";
      if (type == 1) {
        str = '普通客户'
      } else if(type == 2){
        str = 'VIP客户'
      }else if(type == 3){
        str = '签约月结客户'
      }else if(type == 4){
        str = '付款买单客户'
      }else if(type == 5){
        str = '风险客户'
      }
      return str;
    },
      status_format(e){
          let obj={
                0:"待审核",
                1:"正操作",
                2:"预提交",
                3:"已提交",
                4:"已审核",
                5:"已退关",
                6:"已拒接",
          }
          return obj[e]
      },
      kgsFormat(e){
          let str='-'
          if(e){
              str = Number(e).toFixed(3) + 'KGS'
          }
          return str
      }
  },
  methods: {
    // table 排序
    sortChange(e){
      if(e.order){
        this.searchQuery.sort_type=e.order=='ascending'?2:1
        this.searchQuery.sort_key=e.prop
      }else{
        this.searchQuery.sort_type=""
        this.searchQuery.sort_key=""
      }
      this.currentPage=1
      this.getList()
      //排序字段，默认1更新时间倒序,2开航日期，3截单日期，4截关日期、5状态
      
    },
    // 搜索
    searchChange(){
      let obj = Object.assign({},this.searchQuery)
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: this.USER_INFO.user_type == 2 ? "ymp.workbench.shippingOrder.search" : "ymp.workbench.myOrder.search",
        content: JSON.stringify({
          pod: this.searchQuery.delivery_place || '',
          order: this.searchQuery.job_no || '',
          company: this.searchQuery.customer || '',
          type: this.searchQuery.spell_type || '',
          status: this.searchQuery.status || '',
          creat_start: obj.created_time && obj.created_time.length ? this.$moment(obj.created_time[0]).startOf('day').unix() : '',
          creat_end: obj.created_time && obj.created_time.length ? this.$moment(obj.created_time[1]).endOf('day').unix() : '',
          start_time: obj.etd_time && obj.etd_time.length ? this.$moment(obj.etd_time[0]).startOf('day').unix() : '',
          end_time: obj.etd_time && obj.etd_time.length ? this.$moment(obj.etd_time[1]).endOf('day').unix() : '',
        }),
      });
      this.currentPage=1
      this.getList()
    },
    dest_port_input(val) {
      if (val === "") val = null;
      let num = Number(val);
      if (!isNaN(num) || val === "") {
        this.searchQuery.desc = val;
      }
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    resetChange(e) {
      this.searchQuery =  {
          delivery_place:"",//目的港
          job_no:"",// 委托编号
          customer:"",//  合作伙伴公司名== 委托单位
          spell_type:"",// 整拼类型
          etd_time:[],// 开航时间
          created_time:[],// 创建时间
          status:"",
          sort_type:"",
          sort_key:"",
      }
      this.$refs.tbaleRef.clearSort()
      this.$refs.portSelectRef.selectClear()
      this.$refs.shipCompanySelectRef.selectClear()
    },
    async getList() {
      this.loading = true;
      let obj = Object.assign({},this.searchQuery)
      if(obj.etd_time && obj.etd_time.length){
          obj.etd_start = this.$moment(obj.etd_time[0]).startOf('day').unix()
          obj.etd_end = this.$moment(obj.etd_time[1]).endOf('day').unix()
      }
      if(obj.created_time && obj.created_time.length){
          obj.created_time_start = this.$moment(obj.created_time[0]).startOf('day').unix()
          obj.created_time_end = this.$moment(obj.created_time[1]).endOf('day').unix()
      }
      if((obj.spell_type == 6 || obj.spell_type == 7) && this.USER_INFO.user_type == 2){
        if(obj.spell_type == 6){
          obj.lcl_classes = 1
        }else{
          obj.lcl_classes = 2
        }
        obj.spell_type = 2;//重新赋值
      }
      obj.user_id = this.USER_INFO.id
      obj.user_type = this.USER_INFO.user_type
      obj.mobile = this.USER_INFO.mobile
      delete obj.etd_time
      delete obj.created_time
      let params = {
        limit: 15,
        start: this.currentPage - 1,
      };
      params=Object.assign(params,obj)
      CKstore.set('shippingOrderList', JSON.stringify(params));
      let result = await this.$store.dispatch(
          "baseConsole/get_sea_order",
          params
      );
      this.loading = false;
      this.tableData=[]
      if (result && result.success) {
          this.tableData = [].concat(result.data);
          this.total = result.count;
      }
    },
    // 查看详情 
    handleDetail(e){
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: this.USER_INFO.user_type == 2 ? "ymp.workbench.shippingOrder.detail" : "ymp.workbench.myOrder.detail",
        content: JSON.stringify({
          id: e.id || ''
        }),
      });
      if(e && (e.status == 0 || e.status == 6)){
        this.$router.push({
          path: '/console/clients/booking',
          query: {
            id: e.id,
            status: e.status
          }
        })
      }else{
        this.$router.push({
          path: "/console/myEntrust/detailsOrdered",
          query: {
            id: e.id,
            order_id: e.order_id,
            spell_type: e.spell_type == 1 ? 1:2, // 整箱 拼箱
          },
        });
      }
    }
  }
}
</script>
<style scoped lang="less">
.search {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;

  .searchFl {
    width: 90%;
    display: flex;
    align-items: center;

    .searchInput {
      width: 15%;
      padding: 0 6px;
    }
  }
}
.line {
  height: 1px;
  width: 100%;
  background: #ccc
}
.blue{
    color: rgb(0 116 152);
}
.red{
    color: rgb(223 29 29);
}
.black{
    color: rgb(0 1 0);
}
.gray{
    color: gray;
}
.success{
    color: #006edf;
}
.aaa{
    color: #337ab7;
}
</style>